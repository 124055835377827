<template>
  <Layout :loading="loading">
    <b-modal
      :id="modalId"
      title="History"
      ok-only
    >
      <table class="table table-hover">
        <thead class="thead-light">
          <tr>
            <td>Due date</td>
            <td>Last password change</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in row" :key="index">
            <td>{{ $moment(item.due_date).format('DD.MM.YYYY') }}</td>
            <td>
              <span v-if="item.screenshot">
                {{ $moment(item.updated_at).format('DD.MM.YYYY') }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <b-card>
      <div class="d-md-flex align-items-center justify-content-between mb-4">
        <h3 class="mb-3 mb-md-0 ml-1">Password change notifications</h3>
        <div class="d-sm-flex">
          <b-button
              class="mr-2"
              variant="primary"
              @click="handleNotifySubmit()"
          >
            Notify all
          </b-button>
          <div class="mt-2 mt-sm-0 mr-2">
            <Search @onSubmit="handleSearch" />
          </div>
        </div>
      </div>
      <b-card-body v-if="services && services.length">
        <div class="row" v-for="(service, index) in services" :key="`employees-service-${index}`">
          <div
              class="table-responsive mb-5"
          >
            <div class="d-md-flex align-items-center justify-content-between mb-4">
              <h5 class="mb-3 mb-md-0">{{ service.name }} ({{ service.email }})</h5>
              <div class="d-sm-flex">
                <b-button
                    size="sm"
                    class="mr-0"
                    variant="primary"
                    @click="handleNotifySubmit(service.uid)"
                >
                  Notify
                </b-button>
              </div>
            </div>
            <table class="table table-hover">
              <thead class="thead-light">
              <tr>
                <th width="50">#</th>
                <th>Service</th>
                <th>Role</th>
                <th>Username</th>
                <th>Login</th>
                <th width="100" class="text-center">
                  Account type
                </th>
                <th width="100" class="text-center">
                  Due date
                </th>
                <th width="100" class="text-center">
                  Last password change
                </th>
                <th width="100" class="text-right">
                  Actions
                </th>
              </tr>
              </thead>
              <tbody v-if="service.services_employees.length > 0">
              <tr
                  v-for="(service_employee, index) in service.services_employees"
                  :key="`employee-service-role-${index}`"
              >
                <td width="10">{{ index + 1 }}</td>
                <td>
                  {{ service_employee.service.title }} - <span class="text-secondary">{{ service_employee.service.description }}</span>
                </td>
                <td>{{ service_employee.role?.title }}</td>
                <td>
                  <div v-if="service_employee.email">{{ service_employee.email.email }}</div>
                  <div v-else></div>
                </td>
                <td>
                  {{ service_employee.login }}
                </td>
                <td class="text-center">
                  <b-badge variant="success">Service</b-badge>
                </td>
                <td class="text-center">
                  <span>{{ date(passwordChangeRequests(service_employee.password_change_requests).due_date) }}</span>
                </td>
                <td class="text-center">
                  <span>{{ passwordChangeRequests(service_employee.password_change_requests).screenshot ?  date(passwordChangeRequests(service_employee.password_change_requests).updated_at) : '' }}</span>
                </td>
                <td class="text-right">
                  <button @click="handleNotifySubmit(service.uid, service_employee.id)" class="btn btn-sm btn-primary">
                    Notify
                  </button>
                  <button @click="handlerModal(service_employee, service_employee.service_id)" class="ml-2 btn btn-sm btn-outline-info">
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
              </tr>
              </tbody>
              <tbody v-if="service.emails.length > 0">
              <tr
                  v-for="(email, index) in service.emails"
                  :key="`employee-email-${index}`"
              >
                <td><div>{{ index + 1 + service.services_employees.length }}</div></td>
                <td>
                  {{ email.description }}
                </td>
                <td />
                <td>
                  <div v-if="email.email">{{ email.email }}</div>
                  <div v-else></div>
                </td>
                <td>
                </td>
                <td class="text-center">
                  <b-badge variant="info">Email</b-badge>
                </td>
                <td class="text-center">
                  <span>{{ date(passwordChangeRequests(email.password_change_requests).due_date) }}</span>
                </td>
                <td class="text-center">
                  <span>{{ passwordChangeRequests(email.password_change_requests).screenshot ?  date(passwordChangeRequests(email.password_change_requests).updated_at) : '' }}</span>
                </td>
                <td class="text-right">
                  <button @click="handleNotifySubmit(email.employee_uid, null, email.id)" class="btn btn-sm btn-primary">
                    Notify
                  </button>
                  <button @click="handlerModal(email, email.id)" class="ml-2 btn btn-sm btn-outline-info">
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card-body>
      <b-pagination
          v-if="totalRows > pageLimit"
          align="right"
          :value="page"
          :per-page="pageLimit"
          :total-rows="totalRows"
          @change="handlePaginationChange"
      />
    </b-card>
  </Layout>
</template>
<script>
import { mapGetters } from 'vuex';
import Layout from '@/router/layouts/main.vue';
import Search from '@/components/search.vue';

export default {
  name: 'EmailsPage',
  components: {
    Search,
    Layout,
  },
  data() {
    return {
      page: 1,
      search: '',
      pageLimit: 20,
      modalId: null,
      row: null
    }
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeesServices', this.queryParams);
  },
  computed: {
    services() {
      return this.$store.state.employees.employeesServices;
    },
    loading() {
      return this.$store.state.employees.loading;
    },
    totalRows() {
      return this.$store.state.employees.totalEmployeeServices;
    },
    queryParams() {
      return {
        page: this.page,
        per_page: this.pageLimit,
        search: this.search ? this.search : null,
      }
    },
    ...mapGetters('employees', ['employeesOptions']),
  },
  methods: {
    handleSearch(search) {
      this.search = search;
      this.page = 1;
      this.$store.dispatch('employees/getEmployeesServices', this.queryParams);
    },
    handlePaginationChange(page) {
      this.page = page;
      this.$store.dispatch('employees/getEmployeesServices', this.queryParams);
    },
    handleNotifySubmit(employee_uid = '', service_employee_id, email_id) {
      this.$bvModal.msgBoxConfirm('Do you really want to notify?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value) => {
        if (value) {
          const formData = new FormData()
          if (employee_uid) {
            formData.append('employee_uid', employee_uid)
          }
          if (service_employee_id) {
            formData.append('service_employee_id', service_employee_id)
          }
          if (email_id) {
            formData.append('email_id', email_id)
          }
          this.$store.dispatch('employees/notifyEmployee', formData).then(() => {
            this.$store.dispatch('employees/getEmployeesServices', this.queryParams);
          });
        }
      })
    },
    passwordChangeRequests(value) {
      if (!value || !value.length) {
        return {}
      }
      return value[0]
    },
    handlerModal(data, id) {
      this.modalId = id.toString()
      this.row = data.password_change_requests
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 100)
    },
    date(date) {
      if (!date) {
        return ''
      }
      return this.$moment(date).format('DD.MM.YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.id-column {
  width: 15px;
  & > div {
    width: 15px;
  }
}

.table th, .table td {
  padding-left: 2px;
  padding-right: 2px;
}

.table {
  thead {
    th {
      cursor: pointer;
    }
  }
}
.actions-buttons > div {
  width: 80px;
}
</style>
